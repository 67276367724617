export function statusFil(value) {
    switch (+value) {
        case 0:
            return '未开始';
        case 1:
            return '进行中';
        case 2:
            return '已完成';
        default:
            return '';
    }
}
export function goodsStatusFil(value) {
    switch (+value) {
        case 0:
            return '未发货';
        case 1:
            return '已发货';
        default:
            return '';
    }
}

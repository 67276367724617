import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/upload',
        name: 'upload',
        component: () => import(/* webpackChunkName: "about" */ '../components/upload.vue')
    },
    {
        path: '/Index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        redirect: '/Home',
        children: [
            {
                path: '/Home',
                name: 'Home',
                component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
            },
            {
                path: '/Order',
                name: 'Order',
                component: () => import(/* webpackChunkName: "about" */ '../views/Order/Index.vue')
            },
            {
                path: '/OrderDetail',
                name: 'OrderDetail',
                component: () => import(/* webpackChunkName: "about" */ '../views/Order/detail.vue')
            },
            {
                path: '/Refurbishment',
                name: 'Refurbishment',
                component: () => import(/* webpackChunkName: "about" */ '../views/Refurbishment/Index.vue')
            },
            {
                path: '/RefurbishmentDetail',
                name: 'RefurbishmentDetail',
                component: () => import(/* webpackChunkName: "about" */ '../views/Refurbishment/detail.vue')
            },
            {
                path: '/ManagementTeam',
                name: 'ManagementTeam',
                component: () => import(/* webpackChunkName: "about" */ '../views/ManagementTeam/index.vue')
            },
            {
                path: '/Goods',
                name: 'Goods',
                component: () => import(/* webpackChunkName: "about" */ '../views/Goods/index.vue')
            },
            {
                path: '/GoodsOrderList',
                name: 'GoodsOrderList',
                component: () => import(/* webpackChunkName: "about" */ '../views/GoodsOrder/index.vue')
            },
            {
                path: '/LeavingMessage',
                name: 'LeavingMessage',
                component: () => import(/* webpackChunkName: "about" */ '../views/LeavingMessage/index.vue')
            },
        ]
    },
    {
        path: '/page',
        name: 'page',
        component: () => import(/* webpackChunkName: "about" */ '../views/page.vue')
    },
];

const router = new VueRouter({
    base: '/',
    routes
});
router.beforeEach((to,from,next)=>{
    if(to.name === 'login'||to.name === 'page' ){
        next();
    }else{
        let userInfo = sessionStorage.getItem('userInfo')||'{}';
        let info = JSON.parse(userInfo);
        let token = info.access_token;
        if(token){
            next();
        }else{
            router.push('/');
            next();
        }
    }
});
export default router;

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import * as filters from '@/utils/filters';

import { Button, Select,Form,FormItem,Input,
    Container,Header,Aside,Main,Icon,Menu,MenuItem,
    Option,DatePicker,Table,TableColumn,Pagination,MessageBox,
    Dialog,Loading,Upload,Message,Rate,Card,Badge,Avatar,Divider,
    Image
} from 'element-ui';
Vue.config.productionTip = false;
Vue.use(Button)
.use(Select)
.use(Form)
.use(Input)
.use(Container)
.use(Header)
.use(Aside)
.use(Main)
.use(Icon)
.use(Menu)
.use(MenuItem)
.use(Option)
.use(DatePicker)
.use(Table)
.use(TableColumn)
.use(Pagination)
.use(Dialog)
.use(Loading)
.use(Upload)
.use(Rate)
.use(Card)
.use(Badge)
.use(Avatar)
.use(Divider)
.use(Image)
.use(FormItem);
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.use(Loading.directive);
new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
